.Images img {
  width: 100%;
}

div.Images {
  display: flex;
  flex-wrap: wrap;
}
.Images div.container {
  margin-top: 0.25%;
  margin-bottom: 0.25%;
  margin-left: 0;
  margin-right: 0;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  padding-left: 1%;
  padding-right: 1%;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: var(--first-accent-color);
}
.Images .img-container {
  position: relative;
  display: flex;
  justify-content: center;
}
.Images .cover-top,
.Images.cover-bottom {
  position: absolute;
  width: 100%;
  height: 100%;
}
.Images .cover-top::before,
.Images .cover-bottom::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0%);
  transition: clip-path 0.4s ease;
  z-index: 1;
  clip-path: circle(40px at 50% 50%);
}
.Images .cover-top:hover ~ .cover-bottom::before {
  background: rgba(19, 41, 61, 30%);
}

.Images a {
  position: absolute;
  align-self: center;
  opacity: 0;
  transition: all 0.4s ease;
}

.Images a:hover {
  z-index: 2;
  opacity: 1;
}

.Images .cover-top:hover + a {
  z-index: 2;
  opacity: 1;
}

.Images i {
  font-size: 36px;
  color: rgba(255, 255, 255, 0.9);
  padding: 22px;
  border-radius: 50%;
  transition: all 0.4s ease;
}
.Images i:hover {
  transform: scale(1.5);
  color: rgba(255, 255, 255, 1);
}
.Images .background-0 {
  background: rgba(8, 202, 209, 25%);
}
.Images .background-1 {
  background: rgba(52, 150, 153, 25%);
}
.Images .background-2 {
  background: rgba(5, 127, 132, 25%);
}
.Images .background-3 {
  background: rgba(71, 204, 209, 25%);
}
.Images .background-4 {
  background: rgba(3, 79, 82, 25%);
}
.Images .start {
  margin-left: 0.35% !important;
  padding-left: 0.5% !important;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  border-left: 1px solid var(--first-accent-color);
}
.Images .end {
  margin-right: 0.35% !important;
  padding-right: 0.5% !important;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  border-right: 1px solid var(--first-accent-color);
}
.Images .none {
  display: none;
}

@media (pointer: fine) {
  .Images a:hover + .cover-bottom::before {
    clip-path: circle(100% at 50% 50%);
    z-index: 1;
    background: rgba(255, 255, 255, 20%);
    box-sizing: border-box;
    border: 2px solid rgb(255, 255, 255);
    box-shadow: 0 0 2px 1px rgba(255, 255, 255, 0.25);
  }
  .Images a:hover ~ img {
    z-index: 0;
  }
}

@media (max-width: 750px) {
  div.Images {
    display: flex;
    flex-direction: column;
  }
  .Images div.container {
    width: 97% !important;
  }
  .Images div.container {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 6px !important;
    padding-right: 6px !important;
    border-bottom: 0px solid;
    border-top: 0px solid;
    border-left: 1px solid;
    border-right: 1px solid;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-color: var(--first-accent-color);
  }

  .Images .start {
    margin-top: 3px !important;
    padding-top: 6px !important;
    border-top-right-radius: 4px !important;
    border-top-left-radius: 4px !important;

    border-top: 1px solid var(--first-accent-color) !important;
  }
  .Images .end {
    margin-bottom: 20px !important;
    padding-bottom: 6px !important;
    border-bottom-right-radius: 4px !important;
    border-bottom-left-radius: 4px !important;

    border-bottom: 1px solid var(--first-accent-color) !important;
  }
  .Images .cover-top,
  .Images .cover-bottom,
  .Images a,
  .Images .sm-none {
    display: none;
  }
}
