.ProjectFullScreen {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  background-color: rgba(255, 255, 255, 0.45);
  backdrop-filter: blur(15px);
}

.ProjectFullScreen .project-container {
  margin: auto;
  background: rgba(5, 127, 132, 25%);
  border: 1px solid var(--first-accent-color);
  border-radius: 16px;
  height: fit-content;
  width: fit-content;
  max-height: 95vh;
  max-width: 95vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ProjectFullScreen .nav-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 48px;
}
.ProjectFullScreen i {
  font-size: 24px;
}
.ProjectFullScreen i:hover {
  cursor: pointer;
}
.ProjectFullScreen i.hide {
  opacity: 0;
}
.ProjectFullScreen i.hide:hover {
  cursor: auto;
}

.ProjectFullScreen .fa-xmark {
  align-self: flex-end;
  padding: 14px 16px;
}
.ProjectFullScreen .image-container {
  position: relative;
}
.ProjectFullScreen .image-container,
.ProjectFullScreen img {
  max-height: 80vh;
  max-width: 85vw;
  object-fit: contain;
  width: auto;
}
.ProjectFullScreen .image-cover {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: contain;
}
