.ContactsMobile .contact-content {
  display: flex;
  flex-direction: column;
}

.ContactsMobile .email-info {
  font-size: 16px;
  margin: 10px 0 20px;
}

.ContactsMobile .email-info a {
  color: var(--footer-text-color);
}
.ContactsMobile .contact-icons {
  display: inline-flex;
  align-self: center;
}
.ContactsMobile .contact-icons a {
  color: var(--footer-text-color);
  font-size: 36px;
  margin: 0 20px 50px;
  align-self: center;
  display: inline-flex;
  text-decoration: none;
}
.ContactsMobile .contact-icons img {
  width: 32px;
}

@media (min-width: 750px) {
  .ContactsMobile {
    display: none;
  }
}
