.App {
  text-align: center;
}
.App h1,
.App h2 {
  margin: 0;
  background: var(--main-text-color);
  color: var(--theme-background);
}
.App h1 {
  padding: 30px 30px 25px;
  font-size: 36px;
}
.App h1 .name {
  font-size: 32px;
  color: var(--first-accent-color);
}
.App h2 {
  font-size: 18px;
  font-weight: 300;
  text-transform: uppercase;
  padding: 0 30px 30px;
  border-bottom: 16px solid var(--first-accent-color);
}
.App h3 {
  font-size: 32px;
  padding: 35px 25px 0;
}
.App footer {
  padding: 20px 30px 30px;
  font-size: 14px;
  background: var(--first-accent-color);
  color: var(--theme-background);
}
.App footer a {
  color: var(--second-accent-color);
}
.App .Gallery {
  width: 90%;
  margin: 50px auto;
}

@media (max-width: 750px) {
  .App h1 {
    padding: 25px 30px 12px;
    font-size: 24px;
  }
  .App h1 .name {
    font-size: 22px;
  }
  .App h2 {
    font-size: 14px;

    padding: 0 14px 14px;
    border-bottom: 8px solid var(--first-accent-color);
  }
  .App h3 {
    font-size: 26px;
    padding: 20px 25px 0;
  }
  .App footer {
    font-size: 12px;
  }
}
