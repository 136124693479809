button.OpenCloseButton {
  margin-left: -45px;
  margin-top: 20px;
  background: var(--first-accent-color);
  border: none;
  width: 45px;
  height: 25px;
  color: white;
  font-weight: 800;
  text-align: start;
  padding-left: 8px;
  border-radius: 4px;
  transition: all 150ms ease-in-out;
  left: 14px;
  position: relative;
  z-index: 3;
}
button.OpenCloseButton:hover {
  background: var(--theme-background);
  color: var(--first-accent-color);
  border: 1px solid var(--first-accent-color);
  cursor: pointer;
}
button .none {
  display: none;
}

@media (max-width: 1300px) {
  button.OpenCloseButton {
    left: 10px;
  }
}
@media (max-width: 900px) {
  button.OpenCloseButton {
    position: static;
    height: 45px;
  }
}
@media (max-width: 750px) {
  button.OpenCloseButton {
    position: relative;
    text-align: center;
    font-size: 14px;
    align-self: flex-end;
    height: 90px;
  }
}
